<template>
  <div class="">
    <SupportHeading heading="Still Have Questions?
" subHeading="Our customer care team is here for you!
" ></SupportHeading>
    <div class="d-flex flex-column" style="gap:24px; ">
        <div class="" data-aos="fade-left" data-aos-delay="300">
        <div class="support-card d-flex"  @click="$emit('change','email-us')">
            <div class="svg">
                <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" width="54" height="54" rx="27" fill="#F3E7FF"/>
<path d="M17.3417 19.175C17.5528 18.9639 17.8445 18.8333 18.1667 18.8333H36.8333C37.1555 18.8333 37.4472 18.9639 37.6583 19.175M17.3417 19.175C17.1306 19.3861 17 19.6778 17 20V34C17 34.6443 17.5223 35.1666 18.1667 35.1666H36.8333C37.4777 35.1666 38 34.6443 38 34V20C38 19.6778 37.8694 19.3861 37.6583 19.175M17.3417 19.175L25.8501 27.6833C26.7613 28.5946 28.2387 28.5946 29.1499 27.6833L37.6583 19.175" stroke="#571C88" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

            </div>
            <div class="d-flex align-items-center justify-content-between " style="flex-grow:1;gap:20px">
                <div class="">
                    <div class="support-card-name">
                        Email us
                    </div>
                    <div class="support-card-sub-name">
                        We’ll aim to respond in 1 day
                    </div>
                </div>
                <div class="d-flex h-100 align-items-center right-side" style="gap:20px">
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 15L13 10L8 5" stroke="#292D35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </div>
            </div>
        </div>
    </div>
        <!-- Chat -->
        <div class="" data-aos="fade-right" data-aos-delay="300"  >
        <div class="support-card d-flex" :class="!isWithinBusinessHours?'disable-chat':''" @click="openChat()">
            <div class="svg" >
                <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" width="54" height="54" rx="27" fill="#F3E7FF"/>
<path d="M22.8333 24.0833H31M22.8333 28.75H28.6667M31.35 35.2834L38 37.5001L35.7834 30.8501C35.7834 30.8501 36.8333 29.3333 36.8333 26.4167C36.8333 20.9398 32.3935 16.5 26.9167 16.5C21.4398 16.5 17 20.9398 17 26.4167C17 31.8935 21.4398 36.3333 26.9167 36.3333C29.9321 36.3333 31.35 35.2834 31.35 35.2834Z" stroke="#571C88" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

            </div>
            <div class="d-flex align-items-center justify-content-between " style="flex-grow:1;gap:20px">
                <div class="">
                    <div class="support-card-name">
                        Chat with us
                    </div>
                    <div class="support-card-sub-name">
                        {{isWithinBusinessHours?'Available Monday - Friday, 9am-6pm (UTC +3)':'Unavailable'}}
                    </div>
                </div>
                <div class="d-flex h-100 align-items-center right-side" style="gap:20px">
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 15L13 10L8 5" stroke="#292D35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </div>
            </div>
        </div>
        </div>
        <!-- Schedule a call -->
        <div class="d-flex flex-column"  data-aos="fade-left" data-aos-delay="300" style="gap:24px; "  @click="$emit('change','call-us')" >
        <div class="support-card d-flex" >
            <div class="svg">
                <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" width="54" height="54" rx="27" fill="#F3E7FF"/>
<path d="M30.4166 20.5833C31.2766 20.7511 32.1303 21.1304 32.7499 21.75C33.3695 22.3696 33.7488 23.2233 33.9166 24.0833M30.9999 16.5C32.7867 16.6985 34.3946 17.5629 35.6666 18.8333C36.9386 20.1038 37.7992 21.7135 37.9999 23.5M37.9993 32.2228V35.3842C38.0042 36.5927 36.9176 37.6018 35.6975 37.4918C25.1667 37.5 17 29.2575 17.0082 18.7974C16.8984 17.5838 17.9025 16.5012 19.1095 16.5001H22.2771C22.7896 16.4951 23.2863 16.6762 23.6749 17.0096C24.7795 17.9577 25.49 21.1757 25.2166 22.455C25.0032 23.4538 23.9964 24.1525 23.3119 24.8356C24.815 27.4739 27.0038 29.6583 29.6472 31.1584C30.3317 30.4753 31.0319 29.4705 32.0326 29.2575C33.3164 28.9842 36.5551 29.6958 37.4994 30.808C37.8342 31.2025 38.0121 31.706 37.9993 32.2228Z" stroke="#571C88" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


            </div>
            <div class="d-flex align-items-center justify-content-between " style="flex-grow:1;gap:20px">
                <div class="">
                    <div class="support-card-name">
                        Call us or schedule a call
                    </div>
                    <div class="support-card-sub-name">
                        Avaliable Monday - Friday, 9am-6pm
                    </div>
                </div>
                <div class="d-flex h-100 align-items-center right-side" style="gap:20px">
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 15L13 10L8 5" stroke="#292D35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </div>
            </div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import SupportHeading from './SupportHeading.vue';
export default {
components:{
    SupportHeading
},
data(){
    return{
        businessHours: {
        start: '09:00', // 9 AM
        end: '18:00' // 6 PM
      },
      timeZoneOffset: 3 // Israel Standard Time (IST) is UTC+3
    }
},
methods:{
   
    openChat(){
        if(this.isWithinBusinessHours)
        window.open('https://go.crisp.chat/chat/embed/?website_id=1f841a7a-7115-482c-bb41-eeea81f29ba3','_blank')
    }
  },
computed: {
    isWithinBusinessHours() {
      const now = new Date();

      // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const dayOfWeek = now.getDay();

      // Check if the current day is within Monday to Friday (1-5)
      if (dayOfWeek < 1 || dayOfWeek > 5) {
        return false; // Not a business day
      }

      // Get the current time in UTC
      const utcHours = now.getUTCHours();
      const utcMinutes = now.getUTCMinutes();

      // Convert UTC to Israel time
      const israelHours = utcHours + this.timeZoneOffset;
      
      // Adjust for day change if necessary
      const hoursIn24 = israelHours >= 24 ? israelHours - 24 : israelHours;

      // Get the current time in Israel time zone
      const currentTime = `${hoursIn24.toString().padStart(2, '0')}:${utcMinutes.toString().padStart(2, '0')}`;
      console.log(currentTime,this.businessHours)
      // Check if the current time is within business hours
      return currentTime >= this.businessHours.start && currentTime <= this.businessHours.end;
    }
  },

}
</script>

<style scoped>
.support-card{
    border-radius: 8px;
    padding: 16px 20px;
    gap:20px;
    transition: ease all 0.3s;
    cursor: pointer;
    -webkit-user-select: none; 
  -ms-user-select: none;
  user-select: none;
}
.support-card:hover{
    background-color: #F9F8F9;
}
.support-card:active {
    background-color: #E0E0E0; /* Slightly darker background to show press effect */
    box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.2); /* Simulate a pressed effect */
    /* Optional: adjust padding or position to enhance the pressed effect */
}
.support-card-name{
    color:#292D35;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
}
.support-card-sub-name{

font-size: 16px;
font-weight: 400;
line-height: 24px;
color: #667085;


}
.right-side::before{
    content: '';
    height: 100%;
    width: 1px;
    background-color: #EDEDED;
    display: block;
}
.disable-chat{
    opacity: 0.5;
    pointer-events: none;
}
</style>